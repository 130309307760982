import React from "react";
import PropTypes from "prop-types";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import {Button} from "qidigo-form";
import ButtonsBar from "qidigo-form/buttons_bar";
import camelCase from "lodash/camelCase";
import filter from "lodash/filter";
import Helmet from "react-helmet";
import {Link} from "react-router/es";
import {path} from "@app/lib/routes";
import Money from "@app/components/money";
import Telephone from "qidigo-components/telephone";
import Date from "qidigo-i18n/date";
import Rows from "./_rows";
import Image from "qidigo-components/image";
import ConfirmationHeader from "./_confirmation";
import RL24WarningHeader from "./_rl24_warning";
import InstallmentWarning from "./_installments_warning";
import FailedReceiptsWarning from "./_failed_receipts_warning";

import COUNTRIES_TRANSLATIONS             from "qidigo-data/countries.js";
import STATES_TRANSLATIONS                from "qidigo-data/states.js";

const translations = defineMessages({
	"page.title":     {id: "qidigo.dashboard.invoice_show.title", defaultMessage: "Facture #{id}"},

	"action.view": {id: "qidigo.dashboard.invoice_show.action.view", defaultMessage: "Voir"},
	"action.pay":  {id: "qidigo.dashboard.invoice_show.action.pay", defaultMessage: "Payer"},

	"title.from": {id: "qidigo.invoice.show.title.from", defaultMessage: "De"},
	"title.customer": {id: "qidigo.invoice.show.title.customer", defaultMessage: "À"},
	"title.legal_text": {id: "qidigo.invoice.show.title.legal_text", defaultMessage: "Mentions légales"},
	"title.installments": {id: "qidigo.invoice.show.title.installments", defaultMessage: "Liste des versements"},
	"title.amounts": {id: "qidigo.invoice.show.title.amounts", defaultMessage: "Montant dû"},

	"label.tel": {id: "qidigo.invoice.show.label.tel", defaultMessage: "Tél\u00A0: {val}"},
	"label.email": {id: "qidigo.invoice.show.label.email", defaultMessage: "Courriel\u00A0: {val}"},
	"label.created_at": {id: "qidigo.invoice.show.label.created_at", defaultMessage: "Créée le\u00A0: {val}"},
	"label.created_by": {id: "qidigo.invoice.show.label.created_by", defaultMessage: "Par\u00A0: {val}"},
	"label.refunding": {id: "qidigo.invoice.show.label.refunding", defaultMessage: "Remboursement de\u00A0: {id}"},
	"label.due_date": {id: "qidigo.invoice.show.label.due_date", defaultMessage: "Paiement dû\u00A0: {val}"},
	"label.next_due_date": {id: "qidigo.invoice.show.label.next_due_date", defaultMessage: "Prochain paiement\u00A0: {val}"},

	"amount.subtotal": {id: "qidigo.invoice.show.amount.subtotal", defaultMessage: "Sous-total"},
	"amount.fees": {id: "qidigo.invoice.show.amount.fees", defaultMessage: "Frais d'application"},
	"amount.total": {id: "qidigo.invoice.show.amount.total", defaultMessage: "Total"},
	"amount.balance": {id: "qidigo.invoice.show.amount.balance", defaultMessage: "Solde"},
	"amount.cash_gap": {id: "qidigo.invoice.show.amount.cash_gap", defaultMessage: "Arrondissement"},
	"amount.adjustments": {id: "qidigo.invoice.show.amount.adjustments", defaultMessage: "Rabais et ajustements"},
	"amount.initial_subtotal": {id: "qidigo.invoice.show.amount.initial_subtotal", defaultMessage: "Sous-total initial"},
	"amount.payment": {id: "qidigo.invoice.show.amount.payment", defaultMessage: " Paiements reçus"},
	"amount.refund": {id: "qidigo.invoice.show.amount.refund", defaultMessage: " Remboursements reçus"},
    "amount.remaining_credit": {id: "qidigo.invoice.show.amount.remaining_credit", defaultMessage: " Crédit restant"},
	"amount.non_credit_receipt": {id: "qidigo.invoice.show.amount.non_credit_receipt", defaultMessage: "{date}\u00A0: {method}"},
	"amount.applied_credit": {id: "qidigo.invoice.show.amount.applied_credit", defaultMessage: " Crédits appliqués"},
	"amount.deducted_credit": {id: "qidigo.invoice.show.amount.deducted_credit", defaultMessage: " Crédits à déduire"},
	"amount.failed": {id: "qidigo.invoice.show.amount.failed", defaultMessage: " (Paiement échoué)"},

	"installment.STRIPE": {id: "qidigo.invoice.show.installment.STRIPE", defaultMessage: " {date}\u00A0: prélèvement de {amount}"},
	"installment._fallback": {id: "qidigo.invoice.show.installment._fallback", defaultMessage: " {date}\u00A0: « {fallback_label} » de {amount}"},

	"actions.print":   {id: "qidigo.invoice.show.actions.print",   defaultMessage: "Imprimer"},
	"actions.pay":   {id: "qidigo.invoice.show.actions.pay",   defaultMessage: "Payer"},

	"logo": {id: "qidigo.organization.logo",  defaultMessage: "Logo pour l'organisme {organization}"},
	"dashboard.invoice.warning" : {id: "qidigo.invoice.show.warning", defaultMessage: "Des modifications ont été apportées sur cette facture par l'organisme en date du {val}", description: "Message avertissant le client que l'organisme a modifié la facture"},

	"notification.rl24": {id: "qidigo.invoice.show.notification.rl24", defaultMessage: "Des articles sur cette facture sont éligibles au relevé 24."},
	"notification.rl24.link": {id: "qidigo.invoice.show.notification.rl24.link", defaultMessage: "Vous pouvez configurer vos paramètres dans la section Relevés."},
});

translations["title.invoice"] = translations["page.title"];

/**
 * Retire, lorsque présent, le préfixe redondant des keys extraites de l'API.
 */
const cleanup = (source, key) => {
	const regex = new RegExp(`^${key}_`);
	const retval = {};
	source = source[key];
	for (let i in source) {
		const key = i.replace(regex, "");
		retval[key] = source[i];
	}

	return retval;
};

/**
 * Affiche la valeur passée uniquement si elle est truthy.
 */
const maybe_show = (value, format) =>
	value && value.trim() !== "" ? format : null;

const AmountsFragment = (props) => {
	const amounts = props["amounts"] || [];
	const taxes = props["taxes"] || [];
	const adjustments = props["adjustments"] || [];
	const receipts = props["receipts"] || [];
	const {adjustmentsSum, initialSubtotal, refunding} = props;
	const amounts_footer = [
		"adjustments",
		"subtotal",
		"taxes"];

	if (amounts["fees"]) {
		amounts_footer.push("fees");
	}

	amounts_footer.push(
		"total",
		"receipts"
	);

	if (amounts["cash_gap"] && Math.abs(parseFloat(amounts["cash_gap"])) >= 0.01) {
		amounts_footer.push("cash_gap");
	}

	amounts_footer.push("balance");

	if (adjustments && adjustments.length > 0 && !refunding) {
		amounts["adjustments"] = [
			<li key={0}>
				<FormattedMessage {...translations["amount.initial_subtotal"]} />
				<span className="value"><Money value={initialSubtotal} /></span>
			</li>,
			<li key={1}>
				<FormattedMessage {...translations["amount.adjustments"]} />
				<span className="value"><Money value={adjustmentsSum} /></span>
				<ul>
					{adjustments.map((adjustment, key) =>
						<li key={key}>
							<span className="adjustment_label">
								{adjustment["label"]}
							</span>
							<span className="value"><Money value={adjustment["value"]} /></span>
						</li>
					)}
				</ul>
			</li>
		];
	}
	else {
		amounts["adjustments"] = null;
	}

	const nonCreditReceipts = receipts.filter((receipt) => receipt["payment_method"]["internal_id"] !== "USERCREDIT");
	amounts["receipts"] = [];
	if(nonCreditReceipts.length > 0) {
		let message = refunding ? "amount.refund" : "amount.payment";
		const element = <li key={0} className="amounts__receipt">
			<span>
				 <b><FormattedMessage {...translations[message]} /></b>
			</span>
			</li>
		;

		amounts["receipts"].push(element);
	}

	nonCreditReceipts.map((receipt, key) =>
		amounts["receipts"].push(
			<li key={key} className={"amounts__receipt " + (receipt.current_status && receipt.current_status  === 'failed' ? 'failed' : '')}>
				 <span>
					 <FormattedMessage
						 {...translations["amount.non_credit_receipt"]}
						 values={{
							  date: <Date date={receipt["created_date"]} />,
							  method: receipt.payment_method.name
						 }}
					 />
				 </span>
				 <span className="value"><Money value={receipt["total"]} /></span>
				{
					receipt.current_status && receipt.current_status  === 'failed' &&
					<span>
						<FormattedMessage {...translations["amount.failed"]} />
					</span>
				}
			</li>
		)
	);

	const lastIndexOfReceipt = nonCreditReceipts.length - 1;
	const creditReceipts = receipts.filter((receipt) => receipt["payment_method"]["internal_id"] === "USERCREDIT");
	if(creditReceipts.length > 0) {
		const totalCredit = creditReceipts.reduce((currentVal, next) =>
			currentVal + parseFloat(next["total"]), 0
		);

		if(totalCredit !== 0) {
			let message = totalCredit > 0 ? "amount.applied_credit" : "amount.deducted_credit";
			amounts["receipts"].push(
				<li key={lastIndexOfReceipt + 1} className="amounts__receipt">
				 <span>
					 <b><FormattedMessage {...translations[message]} /></b>
				 </span>
					<span className="value"><Money value={totalCredit.toFixed(2)} /></span>
				</li>
			);
		}
	}

	amounts["taxes"] = taxes.map((tax, key) =>
		<li key={`tax--${key}`} className="amounts__tax">
			<span>
				{tax["name"]}
				{" ("}
				{tax["value"]}{"%"}
				{")"}
				{" - "}
				{tax["tax_number"]}
			</span>
			<span className="value"><Money value={tax["total"]} /></span>
		</li>
	);

	return (
		<div className="invoice-page--amounts">
			<h2><FormattedMessage {...translations["title.amounts"]} /></h2>
			<ul>
				{amounts_footer.map((key) => {
					// When given a list of amounts, inserts them here.
					if (Array.isArray(amounts[key])) { return amounts[key]; }
					if (!amounts[key] && amounts[key] !== 0) { return null; }
                    const isCreditNote = key === 'balance' && amounts['total'] < 0 && creditReceipts.length > 0;

                    return (
						<li key={key} className={[`amounts__${key}`].join(" ")}>
							<FormattedMessage {...translations[(isCreditNote ? `amount.remaining_credit` : `amount.${key}`)]} />
							<span className="value"><Money value={amounts[key]} /></span>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

AmountsFragment.propTypes = {
	refunding: PropTypes.bool,
	amounts: PropTypes.object,
	taxes: PropTypes.arrayOf(PropTypes.object),
	adjustments: PropTypes.arrayOf(PropTypes.object),
	receipts: PropTypes.arrayOf(PropTypes.object),
	adjustmentsSum: PropTypes.number,
	initialSubtotal: PropTypes.number,
};

const InstallmentsFragment = (props) => {
	const {installments} = props;

	return (
		<div className="invoice-page--installments">
			<h2><FormattedMessage {...translations["title.installments"]} /></h2>
			<ol>
				{
					installments.map((installment, key) =>
						<li key={key}>
							<FormattedMessage
								{...translations[
									translations[`installment.${installment.payment_method["internal_id"]}`] ?
									`installment.${installment.payment_method["internal_id"]}` :
									"installment._fallback"
								]}
								values={{
									fallback_label : installment.payment_method.name,
									date: <Date date={installment["due_date"]} />,
									amount: <Money value={installment["amount"]} />,
								}}
							/>
						</li>
					)
				}
			</ol>
		</div>
	);
};

InstallmentsFragment.propTypes = {
	installments: PropTypes.arrayOf(PropTypes.object),
};

const LegalTextFragment = (props) => {
	const {legalText} = props;
	if (!legalText || legalText.trim().length === 0) {
		return null;
	}

	return (
		<div className= {"invoice-page--legal_text"}>
			<h2><FormattedMessage {...translations["title.legal_text"]}/>:</h2>
			<div dangerouslySetInnerHTML={{ __html: legalText }} />
		</div>
	);
};

LegalTextFragment.propTypes = {
	legalText: PropTypes.string,
};

const ContractLegalTextFragment = (props) => {
	const {legalText} = props;
	if (!legalText || legalText.trim().length === 0) {
		return null;
	}

	return (
		<div className={`subscriber-invoice-box `}>
			<div className={`subscriber-display`}>
				<h3>
					<FormattedMessage {...translations['title.legal_text']}/>
					<hr/>
				</h3>
			</div>
			<div dangerouslySetInnerHTML={{ __html: legalText }} />
		</div>
	);
};

ContractLegalTextFragment.propTypes = {
	legalText: PropTypes.string,
};

/**
 * Fragment réutilisable pour l'adresse.
 */
const InvoiceAddressFragment = (props) =>
	<ul>
		{props.children}
		{
			(props.address["civic_number_ext"]
			|| props.address["civic_number_and_street"])
				&& <li>
						{
							props.address["civic_number_ext"]
								&& <span>{props.address["civic_number_ext"]} - </span>
						}
						{
							props.address["civic_number_and_street"]
								&& <span>{props.address["civic_number_and_street"]}</span>
						}
					</li>
		}
		{
			(props.address["city_name"]
				|| props.address["state_name"]
				|| props.address["state_slug"])
				&& <li>
					{
						props.address["city_name"]
							&& <span>{props.address["city_name"]}, </span>
					}
					{
						props.address["state_name"]
							&& <span>
									{
										props.address["state_slug"]
											&& STATES_TRANSLATIONS[props.address["state_slug"]]
												? <FormattedMessage {...STATES_TRANSLATIONS[props.address["state_slug"]]} />
												: props.address["state_name"]
									}
								</span>
					}
				</li>
		}
		{
			(props.address["country_name"]
				|| props.address["postal_code"])
				&& <li>
						{
							props.address["country_name"]
							&& <span>
									{
										props.address["country_slug"]
										&& COUNTRIES_TRANSLATIONS[props.address["country_slug"]]
											? <FormattedMessage {...COUNTRIES_TRANSLATIONS[props.address["country_slug"]]} />
											: props.address["country_name"]
									}
									, </span>
						}
						{
							props.address["postal_code"]
								&& <span>{props.address["postal_code"]}</span>
						}
					</li>
		}
		{maybe_show(props.address["phone_number"],
			<li>
				<FormattedMessage
					{...translations["label.tel"]}
					values={{
						val: <Telephone phoneNumber={{
							number: props.address["phone_number"],
							country_id: "CA", // eslint-disable-line
						}} />
					}} />
			</li>
		)}
		{maybe_show(props.address["email"],
			<li>
				<FormattedMessage
					{...translations["label.email"]}
					values={{
						val: props.address["email"],
					}} />
			</li>
		)}
	</ul>
	;

InvoiceAddressFragment.propTypes = {
	address: PropTypes.object.isRequired,
};

/**
 */
const InvoiceShowView = (props, context) => {
	const {formatMessage} = context.intl;
	const {
		invoice,
		payload,
	} = props;
	const {
		installments,
		amounts,
		taxes,
		adjustments,
		items,
		receipts,
		_bundled: {
			missing_rl24_informations,
		},
	} = invoice;

	const nextInstallment = invoice["next_installment"];
	const refunding = !!invoice["refunding_id"];
	const unpaid = amounts["balance"] && amounts["balance"] > 0;
	const showAdjustments = adjustments && adjustments.length > 0;

	const organization = cleanup(invoice, "organization");
	const customer = cleanup(invoice, "customer");
	const company = cleanup(invoice, "company");


	const dueDate = nextInstallment ?
		<li className="invoice-page--due_date"><FormattedMessage {...translations["label.next_due_date"]} values={{val: <Date date={nextInstallment["due_date"]} withTime={false} />}} /></li> :
		<li className="invoice-page--due_date"><FormattedMessage {...translations["label.due_date"]} values={{val: <Date date={invoice["due_date"]} withTime={false} />}} /></li>
		;

	const actionNames = ["print"];
	if (unpaid) {
		actionNames.push("pay");
	}

	const failedReceipts = receipts.filter(r => r.current_status && r.current_status === 'failed');
	const searchParams = new URLSearchParams(window.location.search);
	const isFromSettlement = searchParams && searchParams.get('from') === 'settlement'
	const isFromOrder = searchParams && (searchParams.get('from') === 'pending-order' || isFromSettlement)

	return (
		<section className="dashboard-invoice">
			{
				isFromOrder && <ConfirmationHeader isSettlement={isFromSettlement} invoice={invoice} />
			}
			{
				failedReceipts.length > 0 &&
					<FailedReceiptsWarning
						receipts={failedReceipts}
					/>
			}
			{
				installments && installments.length > 0 && !!unpaid && <InstallmentWarning />
			}
			{
				missing_rl24_informations && !refunding && <RL24WarningHeader warnings={missing_rl24_informations} />
			}
			{
				invoice["with_rl24"] && !refunding &&
					<div className="box-message">
						<p>
							<FormattedMessage {...translations["notification.rl24"]} />
						</p>
						<Link to={path("dashboard.rl24")}>
							<FormattedMessage {...translations["notification.rl24.link"]} />
						</Link>
					</div>
			}
			<Helmet
				title={formatMessage(translations["page.title"], {id: invoice["id"]})}
			/>
			<h1><FormattedMessage {...translations["page.title"]} values={{id: invoice["id"]}} /></h1>
			{maybe_show(invoice["important_updated_at"],
				<div className="dashboard-invoice--warning">
					<FormattedMessage {...translations["dashboard.invoice.warning"]} values={{val: <Date date={invoice["important_updated_at"]} withTime={true} />}} />
				</div>
			)}
			<section className="dashboard-invoice invoice-page">
				<header>
					{
						organization.logo && organization.logo.shown_on_invoice &&
							<Image
							className="invoice-organization--logo"
							src={organization.logo ? organization.logo.path : ""}
							alt={formatMessage(translations["logo"], {organization: organization.name})}
							title={formatMessage(translations["logo"], {organization: organization.name})}
							/>
					}

					<div className="invoice-page--from">
						<h2><FormattedMessage {...translations["title.from"]} /></h2>
						<InvoiceAddressFragment address={organization}>
							<li className="invoice-page--important">
								<Link to={`/u/${organization["slug"]}`}>{organization["name"]}</Link>
							</li>
						</InvoiceAddressFragment>
					</div>
					<div className="invoice-page--customer">
						<h2><FormattedMessage {...translations["title.customer"]} /></h2>
						<InvoiceAddressFragment address={customer}>
							{
								company &&
								<li className="invoice-page--important">{company['name']}</li>
							}
							<li className="invoice-page--important">{customer["full_name"]}</li>
						</InvoiceAddressFragment>
					</div>
					<div className="invoice-page--meta">
						<h2><FormattedMessage {...translations["title.invoice"]} values={{id: invoice["id"]}} /></h2>
						<ul>
							<li><FormattedMessage {...translations["label.created_at"]} values={{val: <Date date={invoice["created_date"]} withTime={true} />}} /></li>
							<li><FormattedMessage {...translations["label.created_by"]} values={{val: invoice["created_by"]}} /></li>
							{
								refunding ?
									<li className="invoice-page--refunding"><FormattedMessage {...translations["label.refunding"]} values={{id: <span>{invoice["refunding_id"]}</span>}} /></li> :
									null
							}
							{
								unpaid ?
									dueDate :
									null
							}
						</ul>
					</div>
				</header>
				<div className="invoice-page--list">
					{
						invoice.model === 'CONTRACT' && invoice["legal_text"] !== '<p><br></p>' &&
						<ContractLegalTextFragment legalText={invoice["legal_text"]} />

					}
					<Rows items={invoice["items"]} invoiceModel={invoice.model} refunding={refunding} showAdjustments={showAdjustments} groupedBy={invoice['settings'] ? invoice["settings"].group_by : 'PRODUCT'} />
				</div>
				<footer>
					<div>
						{
							invoice.model !== 'CONTRACT' &&
							<LegalTextFragment legalText={invoice["legal_text"]}/>

						}
						{
							installments && installments.length > 0 ?
								<InstallmentsFragment installments={installments} /> : null
						}
					</div>
					<AmountsFragment
						refunding={refunding}
						amounts={amounts}
						taxes={taxes}
						adjustments={adjustments}
						receipts={receipts}
						adjustmentsSum={invoice["adjustmentsSum"]}
						initialSubtotal={invoice["initialSubtotal"]}
					/>
				</footer>
			</section>
			<ButtonsBar
				withoutSubmit={true}
			>
				{
					filter(actionNames.map((actionName)=> {
						const fnName = camelCase(`handle_${actionName}`);

						return (
							<Button
								key={actionName}
								className={`invoice--action-${actionName} action-${actionName}`}
								onClick={(e) => props[fnName](e)}>
								<FormattedMessage {...translations[`actions.${actionName}`]} />
							</Button>
						);
					}), (v) => v)
				}
			</ButtonsBar>
		</section>
	);
};

InvoiceShowView.propTypes = {
	invoice: PropTypes.object,
	handlePrint: PropTypes.func.isRequired,
	handlePay: PropTypes.func.isRequired,
	payload: PropTypes.object,
};

InvoiceShowView.defaultProps = {
};

InvoiceShowView.contextTypes = {
	intl: PropTypes.object,
};

export default InvoiceShowView;
