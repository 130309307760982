import React                 from "react";
import PropTypes             from "prop-types";
import Helmet                from "react-helmet";
import {defineMessages}      from "react-intl";

import Form                  from "qidigo-form";
import ButtonsBar            from "qidigo-form/buttons_bar.js";
import {errorMessagesType}   from "qidigo-form/proptypes.js";
import Page                  from "@app/components/page.js";

const translations = defineMessages({
	"title":   {id: "qidigo.forgot.token.title",   defaultMessage: "Mot de passe oublié"},
	"email":   {id: "qidigo.forgot.token.email",   defaultMessage: "Courriel"},
	"return":  {id: "qidigo.forgot.token.return",  defaultMessage: "Retourner à l'accueil."},
	"submit":  {id: "qidigo.forgot.token.submit",  defaultMessage: "Go!"},
	"newPassword":           {id: "qidigo.forgot.token.new_password",           defaultMessage: "Nouveau mot de passe"},
	"passwordConfirmation":  {id: "qidigo.forgot.token.password_confirmation",  defaultMessage: "Confirmation"},
	"passwordMin":  {id: "qidigo.error.messages.password_min",  defaultMessage: "doit contenir un minimum de 8 caractères."},
	"unexpected": {
		id: "qidigo.forgot.token.unexpected",
		defaultMessage: "Le processus de réinitialisation ne peut être complété. {retry} {contact}"
	},
	"used": {
		id: "qidigo.forgot.token.used",
		defaultMessage: "Le lien est eronné, échu ou a déjà été utilisé pour réinitialiser un mot de passe pour ce compte. {retry} {contact}"
	},
	"retry": {
		id: "qidigo.forgot.retry",
		defaultMessage: "Veuillez recommencer le processus de {link} pour obtenir un nouveau lien valide par courriel.",
	},
	"retry.link": {
		id: "qidigo.forgot.retry.link",
		defaultMessage: "réinitialisation de mot de passe",
	},
	"contact": {
		id: "qidigo.forgot.contact",
		defaultMessage: "{link} si des problèmes persistent.",
	},
	"contact.link": {
		id: "qidigo.forgot.contact.link",
		defaultMessage: "Contactez-nous",
	},
	"expired_or_bad_token" : {
		id: "qidigo.forgot.token.expired_or_bad",
		defaultMessage: "Le lien sur lequel vous avez cliqué n'est plus valide, a expiré, ou l'adresse courriel saisie ne correspond pas à celle où le lien a été envoyé. Les liens de validation sont valides pendant 24 heures après leur envoi. Si le lien a expiré, vous pouvez demander un nouveau mot de passe en cliquant sur « Mot de passe oublié ».",
	}
});

const ForgotTokenView = (props, context) => {
	const {formatMessage} = context.intl;

	return (
		<Page name="forgot">
			<Helmet
				title={formatMessage(translations.title)}
			/>
			<section className="forgot"><div className="forgot--forgot-box">
				<h1>{formatMessage(translations.title)}</h1>
				<Form
					onSubmit={(...e)=>props.onSubmit(...e)}
					onChange={(...e)=>props.onChange(...e)}
					onValidate={(...e) => props.onValidate(...e)}
					onValidationError={(errors)=>props.onValidationError(errors)}
					disabled={props.saving}
					values={props.values}
					errors={props.errors}
					presentation="simple"
				>
					<Form.Input name="email"
						icon="email"
						type="email"
						required={true}
						label={formatMessage(translations.email)}
						autoFocus={true}
					/>
					<Form.Input name="new_password"
						icon="password"
						required={true}
						type="password"
						label={formatMessage(translations.newPassword)}
					/>
					<Form.Input name="new_password_confirmation"
						icon="password"
						required={true}
						type="password"
						label={formatMessage(translations.passwordConfirmation)}
					/>
					<ButtonsBar
						loading={props.saving}
						submitLabel={formatMessage(translations.submit)}
					/>
				</Form>
			</div></section>
		</Page>
	);
};

ForgotTokenView.propTypes = {
	errors:    errorMessagesType,
	values:    PropTypes.object,
	saving:    PropTypes.bool,
	onChange:  PropTypes.func,
	onSubmit:  PropTypes.func,
	onValidate: PropTypes.func,
	onValidationError: PropTypes.func,
};

ForgotTokenView.contextTypes = {
	intl: PropTypes.object,
};

export default ForgotTokenView;
export {translations};
