import React, {Component}    from "react";
import PropTypes             from "prop-types";
import moment                from "moment";
import LocalizedDate         from "qidigo-i18n/date";
import {
	defineMessages,
	FormattedMessage,
} from "react-intl";
import filter                from "lodash/filter";
import every                 from "lodash/every";

import FamilySelector        from "@app/views/shared/family/selector";
import Form                  from "qidigo-form";
import Money                 from "@app/components/money.js";
import {age}                 from "@app/views/shared/user/age";
import {RestrictionsFragment} from "qidigo-data/restrictions";
import QidigoBackLocation from "../../../modules/qidigo-router/back_location";
import {navigate}            from "qidigo-router";

const translations = defineMessages({
	"btn.add":  {id: "qidigo.group.offerform.add",  defaultMessage: "Ajouter au panier"},
	"btn.waiting_list": {id: "qidigo.group.offerform.waiting_list",  defaultMessage: "Inscrire à la liste d'attente"},
	"already_on_waiting_list": {id: "qidigo.group.offerform.already_on_waiting_list",  defaultMessage: "Cette personne est déjà inscrite sur la liste d'attente"},
	"now_on_waiting_list": {id: "qidigo.group.offerform.now_on_waiting_list",  defaultMessage: "Cette personne est maintenant inscrite sur la liste d'attente"},
	"txt.fees": {id: "qidigo.group.offerform.fees", defaultMessage: "Frais et taxes inclus"},
	"template.select": {
		id: "qidigo.group.offerform.template.select",
		defaultMessage: "{name} ({age})",
		description: "S'affiche comme suit: `Samuel Dionne-Riel (27 ans)`. L'âge est dans le bon format pour la langue."},
	"guest.blurb":  {id: "qidigo.group.offerform.guest.blurb",  defaultMessage: "Afin de profiter des offres, il est nécessaire de se créer un compte ou de se connecter à votre compte existant."},
	"guest.login":  {id: "qidigo.group.offerform.guest.login",  defaultMessage: "Me connecter"},

	"msg.future":   {id: "qidigo.group.offerform.plan_start_date",  defaultMessage: "Début des inscriptions: {date}"},
	"offer.end_date": {id: "qidigo.group.offer.end_date", defaultMessage: "Date limite d'inscription\u00A0: {endDate}."},
});

export {translations};

/**
 * Avec un state minimal interne (sélection), reçoit une fonction qui permettra d'effectuer l'achat.
 *
 * @extends {Component}
 */
class OfferForm extends Component {
	constructor() {
		super();

		this.state = {
			guest: true,
		};
	}

	/**
	 * Assure une sélection valide au chargement.
	 */
	componentWillMount() {
		// Sélectionne la première option.
		const option = this.props.options[0];
		if (!option) { return; }
		if (option.subscriber) {
			this.setState({
				guest: false
			});
		}
	}

	/**
	 * Effectue le suivi du changement.
	 */
	handleChange(event, value) {
		this.setState({selected: value});
	}

	/**
	 * Fait appel à la fonction donnée pour effectuer l'ajout au panier.
	 * (La fonction a des connaissances sur l'état global de l'application et peut
	 * donc bloquer le changement de page, par exemple, ou afficher état de chargement
	 * pour la zone en question.)
	 */
	handleAdd(event) {
		const {plan} = this.props;
		const {selected} = this.state;
		this.props.onAddToCart({plan: plan.id, subscriber: selected});
	}

	goToLogin() {
		QidigoBackLocation.saveLastLocation();
		navigate('/login');
	}

	renderGuest() {
		const option = this.props.options[0];

		if (!option) { return <div></div>; }

		return (
			<div className="offer-fragment offer-fragment-guest">
				<div className="offer-fragment--price">
					<Money value={option["price"]} /> <small><FormattedMessage {...translations["txt.fees"]} /></small>
				</div>
				<div>
					<p>
						<FormattedMessage
							{...translations["guest.blurb"]}
						/>
					</p>
					<Form.Button onClick={this.goToLogin}>
						<FormattedMessage
							{...translations["guest.login"]}
						/>
					</Form.Button>
				</div>
			</div>
		);
	}

    makeListOfRestrictionCodes = (restrictions) => {
        let groupRestrictions = [];
        restrictions.groups_restrictions.map((groupRestriction) => groupRestriction.restrictions)
            .forEach((restrictions) => {
                groupRestrictions = groupRestrictions.concat(restrictions.filter((restriction) => {
                    return groupRestrictions.find(
                        (addedRestriction) => addedRestriction === restriction
                    ) === undefined;
                }));
            });
        return [].concat(
            restrictions.organization_restrictions,
            restrictions.registration_restrictions,
            restrictions.product_restrictions.map((restriction) => restriction.code),
            restrictions.membership_restrictions,
            groupRestrictions
        );
    }

	render() {
		if (this.state.guest) { return this.renderGuest(); }

		const {
			plan,
			familyMembers,
			newStatus,
			options,
			disabled,
			description,
			groupRestrictions,
		} = this.props;
		const {formatMessage} = this.context.intl;
		const {selected} = this.state;

		const selectedOption = filter(
			options,
			(option) => option.subscriber.toString() === (selected || "").toString()
		)[0];

		const groupsCount = plan.groups.length;
		let withWaitingList = false;

		// Ne devrait pas arriver.
		if (options.length === 0) { return <div></div>; }

		let addFragment = null;

		if (selectedOption) {
            const restrictions = selectedOption.restrictions;
            const restrictionsFlatten = this.makeListOfRestrictionCodes(restrictions);
			const isRestricted = restrictionsFlatten.length > 0;
			const onWaitingList = selectedOption["on_waiting_list"];

            withWaitingList =
                every(plan.groups, (g) => g["waiting_list"]) &&
                (restrictionsFlatten.length === 1 &&
                restrictionsFlatten[0] === "GROUP_IS_FULL" || onWaitingList)
            ;

			if (isRestricted) {
				if (withWaitingList) {
					// Default sur le bouton d'ajout.
					let frag =
						<Form.Button
							className="primary"
							onClick={(...e) => this.handleAdd(...e)}
							disabled={disabled}
						>
							<FormattedMessage
								{...translations["btn.waiting_list"]}
							/>
						</Form.Button>
						;
					// Affiche le message selon si c'est un nouvel ajout ou présence.
					if (newStatus[selected] === "WAITING_LIST") {
						frag =
							<FormattedMessage
								{...translations["now_on_waiting_list"]}
							/>
							;
					}
					else if (onWaitingList) {
						frag =
							<FormattedMessage
								{...translations["already_on_waiting_list"]}
							/>
							;
					}
					addFragment =
						<div className="offer-fragment--waiting-list">
							<div className="offer-fragment--price">
							</div>
							{frag}
						</div>
						;
				}
				else {
					if (restrictionsFlatten.includes('PLAN_NOT_YET_ACTIVE')) {
						if (restrictionsFlatten.length === 1) {
							addFragment =
								<div className="offer-fragment--sub-future">
									<FormattedMessage
										{...translations["msg.future"]}
										values={{
											date: <LocalizedDate date={moment(selectedOption["start_date"])} withTime={true} />,
										}}
									/>
								</div>
						} else {
							addFragment =
								<div>
									<div className="offer-fragment--restrictions">
										<RestrictionsFragment restrictions={restrictions} groupsCount={groupsCount} groupRestrictions={groupRestrictions} />
									</div>
									<div className="offer-fragment--sub-future">
										<FormattedMessage
											{...translations["msg.future"]}
											values={{
												date: <LocalizedDate date={moment(selectedOption["start_date"])} withTime={true} />,
											}}
										/>
									</div>
								</div>
						}
						;
					} else {
						 addFragment =
							  <div className="offer-fragment--restrictions">
								  <RestrictionsFragment restrictions={restrictions} groupsCount={groupsCount} groupRestrictions={groupRestrictions} />
							  </div>
							  ;
					}
				}
			}
			// Autrement, on affiche le petit formulaire.
			else {
				addFragment =
					<div className="offer-fragment--add-to-cart">
						<div className="offer-fragment--price">
							<Money value={selectedOption["price"]} />
							<small><FormattedMessage {...translations["txt.fees"]} /></small>
						</div>
						<Form.Button
							className="offer-fragment--add-to-cart-button"
							onClick={(...e) => this.handleAdd(...e)}
							disabled={disabled}
						>
							<FormattedMessage
								{...translations["btn.add"]}
							/>
						</Form.Button>
					</div>
					;
			}
		}

		return (
			<ul className={`offer-fragment ${withWaitingList ? "with-waiting-list" : ""}`}>
				<li className="offer-fragment--description">
					{description}
					<p className="offer-fragment--end-date">
						{
							selectedOption ?
								<FormattedMessage {...translations["offer.end_date"]}
									values={{
										endDate: <LocalizedDate date={selectedOption["end_date"]} withTime={true} />,
									}}
								/> : "\xa0"
						}
					</p>
				</li>
				<li className="offer-fragment--form">
					<div className="offer-fragment--subscribers">
						<FamilySelector
							options={Object.values(familyMembers)}
							selected={this.state.selected}
							handleChange={(...e) => this.handleChange(...e)}
							disabled={disabled}
						/>
					</div>
					{addFragment}
				</li>
			</ul>
		);
	}
}

OfferForm.defaultProps = {
	newStatus: {},
};

OfferForm.propTypes = {
	groupRestrictions: PropTypes.object,
	plan: PropTypes.object.isRequired,
	familyMembers: PropTypes.object.isRequired,
	newStatus: PropTypes.object,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onAddToCart: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	description: PropTypes.node,
};

OfferForm.contextTypes = {
	intl: PropTypes.object,
};

export default OfferForm;
